//zh.js文件
const Chinese ={
    Common:{
        login:'登录',
        tologin:'登录',
        reLogin:'重新登录',
        Logout:'退出登录',
        out:'退出',
        register:'注册',
        or:'或者',
        or2:'或',
        confirm:'确认',
        yes:'是',
        no:'否',
        close:'关闭',
        haveDone:'完成',
        edit1:'编辑',
        operate:'操作',
        edit2:'修改',
        edit3:'修改',
        del:'删除',
        save:'保存',
        saveAs:'另存为',
        notsave:'不保存',
        all:'全部',
        default:'默认',
        apply:'立即申请',
        buy:'立即购买',
        toPay:'立即支付',
        startDesign:'开始设计',
        validWarning:'检查告警',
        validWarningContact:'如遇困难,请联系客服',
        validWarningLen:'错误信息最多显示20条',
        submitApply:'提交申请',
        freeTrial:'免费试用',
        trial:'试用申请',
        design:'设计产品',
        Back:'返回',
        benefitName:'单方案多场耦合性能自动分析',
        backHome:'返回首页',
        backEasimotor:'返回EasiMotor Online',
        backLamination:'返回冲片库',
        erCode:'官方客服二维码',
        tips:'温馨提示',
        limitTips:'权限提示',
        registerSuccess:'注册成功！',
        submitSuccess:'提交信息成功！',
        submitSuccess2:'提交申请成功！',
        saveSuccess:'保存成功！',
        importSuccess:'导入成功！',
        afterSubmitTips:'我们将在1个工作日内联系您，请注意接听',
        know:'我知道了',
        link:'链接',
        submit:'提交',
        ok:'确定',
        cancel:'取消',
        reset:'重置',
        skip:'跳过',
        select:'选择',
        selectFile:'选取文件',
        resetSelect:'重新选取',
        createLam:'新建冲片',
        next:'下一步',
        prev:'上一步',
        selectSlot:'选择槽型',
        goTo:'前往',
        search:'搜索',
        loading:'加载中',
        nomore:'没有更多了',
        haveAccount:'已有账号？',
        nodata:'暂无数据',
        showType1:'显示图表',
        showType2:'显示列表',
        Overview:'概览',
        Account:'账户设置',
        info:'基本信息',
        security:'账户安全',
        Order:'订单管理',
        onlineModel:'Online 模型',
        createProDesign:'新建产品设计',
        createPro:'新建产品',
        customStructure:'自定义机壳',
        matchHousingdia:'匹配冲片外径',
        customStructureLib:'自定义机壳库',
        addCustomStructure:'添加自定义结构',
        inputHousingName:'请输入机壳名称',
        housingOuterDia:'外径',
        housingInnerDia:'内径',
        saveHousingLib:'保存到机壳库',
        reselectHousing:'机壳库重选',
        selectHousing:'选择机壳',
        autoCalculate:'自动计算',
        autoMatch:'自动匹配',
        import:'导入',
        importLam:'导入冲片',
        importLamination:'导入冲片',
        importRotor:'转子DXF文件导入',
        uploadRotor:'选择DXF转子文件上传',
        magnetGuide:'磁钢区域设置',
        magnetGuideSub1:'请点击“选择”按钮，激活“磁钢”区域选择',
        magnetGuideSub2:'点击DXF模型任意区域设置为“磁钢”区域',
        magnetGuideSub3:'请点击“确定选择”按钮，退出“磁钢”区域',
        magnetGuideSub4:'点击已选择的“磁钢”区域。当前只有“磁钢”区域需要对单个区域进行详细设置。',
        magnetGuideSub5:'完成表格内所有数据设置后，磁钢区域设置流程完成',
        guideSkipBtn:'我已知道，跳过',
        guideDoneBtn:'立即使用',
        importRotorSub:'请确保导入的DXF文件只包含转子部分',
        importLaminationSub:'通过以下方式导入冲片到数智工程师',
        importLaminationMotorType:'请选择冲片适用的电机类型，后期可以通过电机类型筛选出该冲片',
        analyseSub:'选择初始方案设计需要分析的流程',
        housingParameter:'机壳参数',
        AnalysisProcess:'分析流程',
        ResultFile:'结果文件',
        engineer:'数智工程师',
        library:'产品库',
        goods:'价格',
        chooseSku:'选择适合你的套餐方案',
        viewGoods:'查看功能详情',
        packageTips:'套餐不区分电机类型，目前支持的电机类型有:鼠笼式三相感应电机、变频鼠笼感应电动机、交流永磁同步电动机、无刷永磁直流电机',
        packageName:'一键多场耦合分析',
        byFree:'（免费试用）',
        byTimes:'（按次）',
        byMonth:'（按月）',
        byYear:'（按年）',
        tolibrary:'返回产品库',
        ProFile:'产品文件夹',
        readDone:'我已阅读并同意',
        pleaseRead:'请先全部阅读完毕',
        policy1:'《数智工程师用户协议》',
        policy2:'《数智工程师隐私政策》',
        subTitle:'一个账号，可登录所有麦科斯韦旗下产品',
        subTitleTips:'EasiMotor Online、数智工程师、供需对接广场、电机研习社、在线展会、公文包等',
        dxfImport:'DXF文件导入',
        dxfImportSub:'仅支持上传转子DXF文件',
        reselectDxf:"暂无合适的定子冲片，请重新选择",
        rotorPosition:'转子位置',
        rotorStructure:'转子结构',
        innerRotor:'内转子',
        outerRotor:'外转子',
        rotor:'转子',
        stator:'定子',
        model:'区域设置',
        geometry:'几何模型',
        parameterErr:'请先正确设置所有参数值',
        saveStructure:'保存机壳',
        structureName:'机壳名称',
        saveTo:'保存到',
        saveLam:'保存冲片',
        example:'示意图',
        uploadPic:'点击上传图片',
        useDefautImg:'不上传，使用默认示意图',
        defautImg:'默认示意图',
        uploadPicSub:'上传示意图作为机壳头像，尺寸推荐200*200px',
        setRegion:'设置选中区域',
        regionType:'区域类型',
        region:'区域',
        region2:'区域',
        haveSelect1:"还有",
        haveSelect2:"个区域未完成参数设置",
        haveSelect3:"个区域未完成参数设置",
        allSelect:"所有区域均已完成参数设置",
        selectRegion:'选择区域',
        cofirmRegion:'确定选择',
        addline:'添加行',
        deleteline:'删除行',
        saveLamSub:"您的冲片已成功导入至冲片库，您可以点击下方按钮选择继续导入或返回产品库。",
        saveLamSub2:"您的冲片已成功导入至冲片库，您可以点击下方按钮选择继续导入或返回EasiMotor Online冲片库。",
        continue:"继续导入",
        setRotorModel:'请先设置转子模型区域',
        setRegionFirst1:'请先设置',
        setRegionFirst2:'区域参数',
        setRegionType:'请先设置所有区域类型',
        setRotorGeometry:'请先设置转子几何数据',
        setStator:'请先设置定子参数',
        setCycles:'极数与周期数之比应为正整数',
        saveLamLib:'您要保存到冲片库吗？',
        quitEdit:'仍要退出编辑？',
        notSaveMen:'如果不保存，你的更改内容将会消失。',
        cyclePlaceholder:'输入周期后激活区域选择',
        selectStator:'请选择定子类型',
        custom:'自定义',
        editPara:'修改参数',
        importTip:'这个操作将会删除所有已定义的数据，你确定要继续么？',
        notFound:'页面不存在！',
        edit:{
            export:'导出',
            importData:'数据导入',
            changeMode:'切换模式',
            exportOnline:'导出到Online',
            exportOnlineTip:'',
            exportLamToLib:'导出冲片到库',
            exportHousingToLib:'导出机壳到库',
            exportLam:'冲片导入到',
            exportLamTip:'',
            exportEasiMotorOnline:'导出到EasiMotor Online',
            deletePro:'删除产品',
            reName:'重命名',
            reDesign:'重新设计',
            addFile:'添加到',
            contrast:'产品对比',
            support:'技术支持',
            copy:'复制产品',
        },
        userRegister: {
            realName: "姓名", //姓名
            userName:'用户名',//用户名
            password:'密码',
            company: "企业名称", //企业名称
            college: "学校名称", //学校名称
            position: "岗位或职务", //岗位或职务
            profession: "所属院系专业", //所属院系专业
            provinceArea:'省份、城市、区县',
            country:'国家',
            province:'省份',
            city:'城市',
            area:'区县',
            address:'详细地址',
            socialCreditCode:'企业统一社会信用代码',
            staffNumber: "企业人员规模", //企业人员规模
            teamNumber: "研发团队规模", //研发团队规模
            annualOutput: "企业年产值", //企业年产值
            phone:'手机号码',
            unitNature:'单位性质',
            unitNature1:'电机制造企业',
            unitNature2:'电机使用企业',
            unitNature3:'电机供应商',
            unitNature4:'高校',
            unitNature5:'研究所',
            smsCode:'验证码',
            mail:'电子邮箱',
            refereePhone:'推荐人手机号(非必填)',
            confirmPassword:'确认密码',
            haveAccount:'已有账户？',
        },
        valid: {
            pleaseInput:'请输入',
            pleaseSelect:'请选择',
            inputOrSelect:'请输入或选择',
            submitSelect:'确定选择',
            realName: "请输入姓名", //姓名
            userName:'请输入用户名',
            password:'请输入密码',
            company: "请输入企业名称", //企业名称
            college: "请输入学校名称", //学校名称
            provinceArea:'请选择省份、城市、区县',
            country:'请输入国家',
            address:'请输入详细地址',
            socialCreditCode:'请输入企业统一社会信用代码',
            socialCreditCodeFormat:'请输入正确的企业统一社会信用代码格式',
            position: "请输入岗位或职务", //岗位或职务
            profession: "请输入所属院系专业", //所属院系专业
            phone: "请输入手机号",
            mail: "请输入电子邮箱",
            smsCode: "请输入验证码",
            smsCodeError: "请输入正确的验证码",
            phoneFormat: "请输入正确的手机号码格式",
            refereePhoneFormat: "请输入正确的手机号码格式(或不填)",
            mailNone: "邮箱不能为空",
            mailFormat: "请输入正确的电子邮箱格式",
            mailFormat2: "请输入您的教育邮箱(.edu.cn)",
            mailFormat3: "建议输入您的企业邮箱",
            unitNature: "请选择单位性质", //单位性质
            staffNumber: "请选择企业人员规模", //企业人员规模
            teamNumber: "请选择研发团队规模", //研发团队规模
            annualOutput: "请选择企业年产值", //企业年产值
            passwordAgain:'请再次输入密码',
            differPassword:'两次输入的密码不一致',
            userNameFormat:'由字母、数字、下划线组成，以字母开头',
            passwordFormat:'6-18位，由大、小写字母和数字组成',
            newPasswordFormat:'请输入8-32位字母或数字',
            getSmsCode:'获取验证码',
            getSmsCode1:'获取短信验证码',
            getSmsCode2:'获取邮箱验证码',
            resend:'重新发送',
            motorType:'您所属单位涉及电机产品的电机类型（多选）',
            motorTypeInput:'请输入电机类型',
            otherMotorType:'其他电机类型',
            applicationScene:'您所属单位涉及电机产品的应用领域（多选）',
            applicationSceneInput:'请输入应用领域',
            otherapplicationScene:'其他应用领域',
            productType:'您所属配套企业的产品类型（多选）',
            productTypeInput:'请输入产品类型',
            otherproductType:'其他产品类型',
        },
    },
    //* route-menu+store-index完成(Common)
    routeMenu:{
        result:'输出结果',
        Success:'设计成功',
        Fail:'设计失败',
        Computing:'设计中',
        Draft:'草稿箱',
        folder:'文件夹',
        ImportApply:"应用领域",
        applyParam:'领域参数',
        ImportData:"输入数据",
        Report:"设计报告",
        Craft:"工艺文件",
        Winding:"绕线图",
        Drawing:"图纸",
        Bom:"BOM表",
        example:'案例演示版本',
        Member:'人员权限管理',
        memberControl:'用户管理',
        Person:'用户',
        Group:'用户组',
        ResourceControl:"资源权限管理",
        Resource:"系统资源授权",
        Account:'账户设置',
        UserSettingOverview:'概览',
        AccountInfo:'基本信息',
        AccountSecurity:'账户安全',
        Order:'订单管理',
        engineer:'数智工程师',
        messageProduct:"您尚未选中任何产品",
        messageDesign:"您的产品未保存，请重新开始设计",
        messageContrast:"请重新选中产品进行对比",
        cannotTrial:'当前不能申请免费试用',
        loginAgain:'您的登录信息已失效，请重新登录',
        toCertificate:"请先填写认证信息",
    },
    //? app完成(common)
    //? view-userSetting 完成
    //? views-user-UserSettingIndex(无)
    //? view-share(无)
    //? view-ResultIndex(无)
    //? view-MemberIndex
    MemberIndex:{
        notEnterprise:'此账户不是企业账户',
    },
    //? views-Home 完成
    Home:{
        footer:{
            Hotline:'售前咨询热线',
            HotlineNum:'0571-85464125/6/7',
            PTC:'专业技术咨询',
            CPI:'全方位产品解读',
            GS:'成熟解决方案',
            SCCA:'成功客户案例分析',
            PnS:'产品与服务',
            CS:'咨询服务',
            CS1:'电机及机电系统咨询',
            CS2:'电源设计与仿真',
            CS3:'电气系统设计',
            SnS:'支持与学习',
            SnS1:'在线帮助',
            SnS2:'网络在线研讨',
            Company:'公司',
            Company1:'关于我们',
            Company2:'人才',
            Company3:'联系我们',
            Company4:'安全保障',
            Company5:'进入官网',
            Focus:'关注我们',
            WXOfficial:'易泰达官微',
            WXMotor:'电机研习社',
            CopyRight1:'杭州易泰达科技有限公司 版权所有',
            CopyRight2:'浙ICP备10046028号-4',
            CopyRight3:' 浙公网安备 33010802009522',
        }
    },
    //? views-EngineerIndex完成(common+routeMenu)
    EngineerIndex:{
        designStatus:'设计状态',
        applicationScene:'应用领域',
        motorTypeCode:'电机类型',
        motorStructureCode:'机壳',
        selectapplicationScene:'选择应用领域',
        selectmotorTypeCode:'选择电机类型',
        selectmotorStructureCode:'选择机壳',
        companyStructure:'企业自带结构',
        systemStructure:'系统自带结构',
        noStructure:'暂无机壳数据',
        noEnterpriseStructure:'暂无数据，升级为企业账户后可用',
        SatisfiedAll:'满足约束条件',
        SatisfiedPart:'满足部分约束条件',
        createDesign:'新建产品设计',
        systemLib:'系统模板库',
        systemStandardLib:'系统标准库',
        companyLib:'企业模板库',
        companyStandardLib:'企业标准库',
        customHousing:"个人库",
        housingBasicInfo:'基本信息',
        housingStructureInfo:'结构信息',
        housingUsedPro:'使用机壳产品',
        proHousing:"已建产品机壳",
        housingDetail:'查看详情',
        housingCopy:'创建副本',
        onlineToVirtual:'您的项目正在由数智工程师打开并新建',
    },
    //? views-DesignIndex完成(common+routeMenu+ProList)
    DesignIndex:{
        reference:'默认参考值',
        applyParams:'应用领域参数',
        performanceParams:'性能参数',
        inputPunchingSheetName:'请输入冲片名',
        punchingNameError1:'冲片名称不能为空',
        housingNameError1:'机壳名称不能为空',
        punchingNameError2:'不能使用特殊字符，最多包含50个字符',
        applyConverse:'指标转换',
        closeApplyParam:'取消并关闭',
        saveApplyParam:'保存后关闭',
        noBenefit:'您已无设计权限，如需继续使用，请选择购买',
        orContact:'或者联系客服！',
        noBenefitMetion:'暂无权限使用该方案功能，如需使用请提交申请',
        noDownloadMetion:'暂无权限使用该下载功能，如需使用请先购买套餐',
        noShareMetion:'暂无权限使用该分享功能，如需使用请先购买套餐',
        subAccountMention:'当前账户不可购买，如需使用请通知企业主账户购买',
        noCalcTimeMetion:'您的核时已用完，如需继续使用请提交申请',
        cancelDesign:'取消设计',
        submitDesign:'确定设计',
        toDetail:'查看详情',
        noSupport:'当前版本暂不支持此功能',
        toEasimotorOnline:'可前往Easimotor Online, 新建该电机类型冲片',
        tableData:{
            title1:'转折点功率(kW)',
            title2:'转折点速度(rpm)',
            title3:'转折点转矩(Nm)',
            title4:'最小转速(rpm)',
            title5:'最大转速(rpm)',
        },
        paramsError1:'数值不能为空',
        paramsError2:'满车重量须大于等于空车重量',
        paramsError3:'持续爬坡质量须大于等于空车重量',
        selectScheme:'选择设计方案',
        saveToDraft:'保存到草稿箱',
        saveToDesign:'保存并开始设计',
    },
    //? views-user 完成
    //views-user-UserCertificate 已完成(common)
    UserCertificate:{
        title:'认证信息填写',
        subTitle:'为了确保您可以正常使用麦科斯韦产品，请确认以下信息并完成审核',
        wechatTipsTop:'您需要微信扫一扫上方二维码，加麦科斯韦公司官方客服的企业微信位好友，经过官方客服审核通并收到通过短信后，您可以重新登录开始体验！',
        continue:'继续浏览！',
    },
    //views-user-UserLogin 已完成(common)
    UserLogin:{
        trial:'试用申请已开放，快速获得14天免费试用！',
        trial2:'立即注册，开通免费5次计算权限',
        step1:'登录账号',
        step2:'提交试用申请',
        step3:'审核通过即可获得',
        light1:'无需学习复杂软件操作',
        light2:'无需购买昂贵的软件',
        light3:'无需耗费几天等待计算结果',
        light4:'电磁、应力、温升、控制全覆盖',
        light5:'手机、PAD、电脑随时算',
        light6:'三分钟大规模云计算完成全流程',
        light7:'自动生成优化设计报告、图纸、BOM表、工艺文件',
        contact:'您可以通过以下方式尽快与我们取得联系：',
        scanLeft:'官方微信客服：扫描左侧二维码',
        scanTop:'官方微信客服：扫描上方二维码',
        Hotline:'服务热线：0571-85464125/6/7',
        Mail:'官方邮箱：Marketing@easi-tech.com',
        LoginTitle:'用户名密码登录',
        noAccount:'您还没有数智工程师账号？',
        forget:'忘记密码？',
        register:'立即注册',
        browser:'推荐使用以下浏览器使用麦科斯韦产品',
        loginSuccess:'登录成功',
    },
    //views-user-UserRegister 已完成(common)
    //views-user-UserOntrial 无
    //views-user-UserPay 已完成(common)
    UserPay:{
        step1:{
            title:'确认订单信息',
            name:'名称',
            price:'单价',
            num:'数量',
            totalAmount:'金额总计',
            payAmount:'应付金额',
            submit:'提交订单',
            tips:'请确认以上信息后再付款，如有疑问请及时联系我们',
        },
        step2:{
            title:'收银台',
            orderDetail:'订单详情',
            details:'明细',
            times:'计算次数',
            expired:'有效期',
            expiredTo:'有效期至',
            monthExpired1:'',
            monthExpired:'个月内有效',
            yearExpired:'年内有效',
            subTitle:'您的订单提交成功！可以付款咯',
            orderNo:'订单编号',
            payAmountFinal:'实际支付',
            select:'支付方式',
            AliPay:'支付宝支付',
            WeChat:'微信支付',
        },
        done:{
            paySuccess:'支付成功',
            payFail:'交易失败',
            ifContact1:'如有疑问或者需要定制，',
            ifContact2:'如需开具发票，',
            contact:'请联系我们的销售团队',
        }
    },
    //* views-user-UserRequest 已完成(common+UserTrial)
    UserRequest:{
        price:'价格',
        five:'免费5次',
        tips1:'申请免费试用请务必添加官方微信客服！',
        tips2:'申请成功可享14日有效期，5次计算权限。',
    },
    //views-user-UserTrial(common)
    UserTrial:{
        free:'免费',
        trial:'试用申请',
        trial1:'免费试用申请',
        trial2:'再次免费',
        trial3:'试用申请',
        submitSuccess1:'提交试用申请成功！',
        submitSuccess2:'提交再次试用申请成功！',
        uploadSuccess:'上传成功！',
        afterSubmit1:'您已成功提交数智电机工程师试用申请，正在审核中......',
        afterSubmit2:'您需要微信扫一扫左边二维码，加麦科斯韦公司官方客服的企业微信为好友，及时了解申请进度！',
        afterSubmit3:'经过官方客服审核通过并收到通过短信后，您可以获得《数智工程师产品使用手册》，并可登录进行试用。',
        failSubmit1:'提交试用申请失败！',
        failSubmit2:'企业客服会尽快与您取得联系，帮助您试用申请成功。',
        failSubmit3:'未添加官方客服好友的请尽快添加。',
        failSubmit4:'您可以重新提交',
        imgError1:'当前仅支持上传图片JPG/jpeg/png格式!',
        imgError2:'上传图片大小不能超过10MB!',
        fileError:'上传文件大小不能超过20MB!',
        csvFileError:'请上传csv格式的文件!',
        xlsFileError:'请选择Excel文件!',
        xlsUploadErr:'数据导入失败，数据匹配异常。',
        license:'企业营业执照副本图',
        imgTips1:'支持jpg/jpeg/png格式的图片，大小≤10M',
        imgTips2:'露出证件四角，请勿遮挡或者模糊，保证信息清晰可见',
        imgTips3:'需提供三证合一的营业执照原件扫描件或加盖公章的复印件',
        imgTips4:'请提供有效期内的营业执照，如函件过期则无法通过',
        example:'示例图',
        selectImg:'已选中图片',
        selectDxf:'已选中dxf文件',
        uploadLicense:'上传营业执照副本',
        staffNumber:'企业人员规模',
        teamNumber:'研发团队规模',
        annualOutput:'企业年产值',
        select:'请下拉选择',
        needFollow:'是否需要专人为你讲解和介绍',
        needApp:'是否希望在手机App上试用',
        proFunction:'数智工程师你最想体验的功能（可多选）',
        subTop:'您的数智电机工程师试用已到期，请填写您的试用体验反馈表，有机会获得再次试用！',
        afterTrialFavorite:'试用后，您最喜欢数智电机工程师哪个功能？（可多选）',
        afterTrialNotGood:'试用后，您认为数智电机工程师哪些功能需要完善？（可多选）',
        afterTrialBuy:'试用后，贵司是否有计划购买数智电机工程师？',
        addFunction:'试用后，您认为数智电机工程师还需增加哪些功能？',
        otherFunction:'其他您希望的功能',
        afterTrialWanted:'请输入其他您希望的功能(建议不少于20字)',
        valuation:'请写下您对数智电机工程师的总体试用评价！',
        afterTrialComment:'请输入您的试用评价(建议不少于20字)',
    },
    //? view-userSetting 完成
    //views-user-UserSettingIndex(无)
    //views-user-UserSetting-Overview完成(common)
    Overview:{
        benefit:'权益',
        benefitName:'权益名称',
        operatorType:'操作明细',
        benefitNum:'数量',
        createTime:'操作时间',
        history:'历史记录',
        noBenefit:'暂无权益',
        accountInfo:'账号信息',
        accountType:'账号性质',
        buyOnce:'单次购买',
        version:'版本升级',
        versionInfo:'多方案优化，企业定制化',
        operatorType1:'获得',
        operatorType2:'使用',
        operatorType3:'返还',
        operatorType4:'过期',
        infinite:'无限次数',
    },
    //views-user-UserSetting-AccountInfo完成(common)
    AccountInfo:{
        motorTypeTag:'电机类型标签',
        applicationSceneTag:'应用领域标签',
        no:'暂无',
    },
    //views-user-UserSetting-AccountSecurity完成(SubMenu+common)
    AccountSecurity:{
        password:'登录密码',
        passwordInfo:'用于保护账号信息与登录安全',
        tel:'登录手机号码',
        telInfo:'用于登录账号，重置密码或其他安全验证',
        changePassword:'修改密码',
        changeTel:'修改手机号码',
        oldPassword:'原密码',
        newPassword:'新密码',
        newTel:'新手机号码',
        newPassword2:'确认新密码',
        fromType1:'通过手机验证码修改',
        fromType2:'通过密码修改',
        oldNewPassError:'新密码与旧密码一致',
        oldNewTelError:'新手机号码与旧号码一致',
        passwordSuccess:'密码已成功修改',
        telSuccess:'手机号码已成功修改',
    },
    //views-user-UserSetting-OrderIndex完成(common)
    OrderIndex:{
        search:'输入订单名称进行搜索',
        createTime:'创建时间',
        expiredTime:'到期时间',
        to:'至',
        startTime:'开始日期',
        finishTime:'结束日期',
        show:'显示',
        pageNo:'每页显示数量',
        noOrder:'暂无订单',
        skuname:'名称',
        skuoption:'选项',
        skunum:'数量',
        skuamount:'价格',
        actualPay:'实付款',
        skustatus:'状态',
        skueditor:'操作',
        orderNo:'订单号',
        copy:'复制订单号',
        remain:'剩余',
        clipboard:'订单编号已复制到剪切板',
        delOrder:'删除订单',
        cancelOrder:'取消订单',
        selectAll:'全选',
        select1:'已选中',
        select2:'个订单',
        select3:'项',
        pageSize1:'共',
        pageSize2:'页',
        delTips:'删除后该数据不可恢复，是否继续？',
        cancelTips:'是否确定取消此订单？',
        clipboardError:'暂不支持复制',
        allOrder:'全部订单',
        orderStatusTo:'待付款',
        orderStatusSuccess:'交易成功',
        orderStatusFail:'交易失败',
        orderStatusCancel:'已取消',
    },
    Goods:{
        coreFunction:'核心功能模块',
        coreFunction1:'快速设计电机产品并输出结果',
        coreFunction2:'自动进行电机多目标优化',
        coreFunction3:'根据材料价格自动更新设计方案',
        coreFunction4:'自动进行电机多场耦合分析计算',
        coreFunction5:'自动进行电机性能虚拟实验',
        coreFunction6:'自动生成电机MAP图',
        coreFunction7:'自动生成抗退磁能力分析报告',
        coreFunction8:'自动生成温升分析报告',
        coreFunction9:'自动生成应力分析报告',
        coreFunction10:'自动生成振动噪声分析报告',
        coreFunction11:'自动生成成本分析报告',
        coreFunction12:'自动生成完整工艺文件',
        coreFunction13:'自动生成绕线图',
        coreFunction14:'自动生成完整图纸',
        coreFunction15:'自动生成BOM表',
        coreFunction16:'自动生成销售支持数据',
        libraryFunction:'库功能选项',
        libraryFunction1:'企业产品库管理',
        libraryFunction2:'定制化企业产品数据库、冲片库',
        libraryFunction3:'冲片、零部件与材料库',
        libraryFunction4:'行业标准库',
        outputProject:'输出项目',
        outputProject1:'方案数据对比报告',
        outputProject2:'优选方案介绍报告',
        outputProject3:'电机基本场图分析报告',
        outputProject4:'电磁性能对比分析报告',
        outputProject5:'MAP图分析报告',
        outputProject6:'抗退磁能力分析报告',
        outputProject7:'温升分析报告',
        outputProject8:'应力分析报告',
        outputProject9:'振动噪声分析报告',
        outputProject10:'成本分析报告',
        outputProject11:'结果下载与分享功能',
        industryChain:'产业链协同功能',
        industryChain1:'自动生成三维模型',
        industryChain2:'自动生成电机数字样机虚拟样机模型',
        industryChain3:'自动生成设备加工代码',
        industryChain4:'客户、供应商数据互通与报价',
    },
    //? view-result 完成
    //views-result-bom(无)
    //views-result-craft(无)
    //views-result-drawing(无)
    //views-result-winding(无)
    //views-result-importApplyScene完成(DesignIndex+Common)
    //views-result-importData完成(Common)routeMenu
    importData:{
        basic:'基本需求',
        restrictions:'约束条件',
        chooseLamination:'指定冲片',
        lamination:{
            punchingName:'冲片名称',
            motorType:'电机类型',
            statorExternalDiameter:'定子外径',
            statorInternalDiameter:'定子内径',
            rotorExternalDiameter:'转子外径',
            rotorInternalDiameter:'转子内径',
            rotorExternalInner:'转子外径须大于转子内径',
            number:'极数',
            statorSlotNum:'定子槽数',
            slotType:'槽型',
            source:'来源',
            statorSlot:'定子槽',
            rotor:'转子',
            rotorSlot:'转子槽',
            stator:'定子',
        },
        optimizeTarget:'优化目标',
        scanVariables2:'边界条件',
        schemeTitle:'优化设计',
        scanVariables:'初始方案',
        advanced:'高级设置',
        analysisProcess:'分析流程',
        analysisError:'请至少勾选一项分析流程',
    },
    //views-result-report完成(routeMenu)
    report:{
        schemeDesignType:'方案介绍',
        refreshImgTip:'数据未加载,可尝试手动刷新',
        noDataTip:'暂无数据',
    },
    //? view-member 完成
    //views-member-AdminAddPerson完成(Common)
    AdminAddPerson:{
        createPerson:'创建用户',
        userName:'登录名称',
        userNameValid:'格式为英文字母、数字、.、_或-，最多包含64个字符',
        realName:'真实姓名',
        realNameValid:'格式为中英文字母、数字、.、_或-，最多包含32个字符',
        addPerson:'添加用户',
        password:'用户密码',
        passwordValid:'长度为8-32位字母数字',
        passError1:'请输入密码',
        passError2:'格式不正确，请输入8-32位字母或数字',
        userNameError1:'登录名称不能为空',
        userNameError2:'格式不正确，请输入英文字母、数字、.、_或-，最多包含64个字符',
        realNameError1:'真实姓名不能为空',
        realNameError2:'格式不正确，请输入中文、英文字母、数字、.、_或-，最多包含32个字符',
        addError:'请添加至少一位用户',
    },
    //views-member-AdminPerson完成(Common+AdminAddPerson+OrderIndex)
    AdminPerson:{
        person:'用户',
        createPerson:'创建用户',
        password:'登录密码',
        lastTime:'最后操作时间',
        delPerson:'删除用户',
        delPersonError:'仅删除用户，用户创建的项目冲片数据不会被清除，是否继续？',
        resetPass:'重置密码',
    },
    //views-member-MemberOverview(Common+Overview)
    MemberOverview:{
        basicData:'基础数据',
        subAccountNum:'用户数',
        projectNum:'项目数',
        laminationNum:'冲片数',
        calcTime:'核时数',
        accountIdentity:'账号身份',
        hour:'小时',
    },
    //? view-engineer 无
    //? Components-user 完成
    //User-CountEnd 无
    //User-UserNav 完成(Common)
    UserNav:{
        Pay:'支付中心',
        design:'设计电机',
    },
    //User-Sidebar 已完成(common)
    //User-Tag 已完成
    Tag:{
        CommonVIP:'普通VIP',
        TrialVIP:'试用VIP',
        EnterpriseVIP:'企业VIP',
    },
    //? Components-result 完成
    //*Components-result-ApplyCurve 完成
    ApplyCurve:{
        rotateSpeed:'转速[rpm]',
        torque:'转矩[Nm]',
        curveTitle:'工况特性曲线',
        RatedSolution:'额定工况',
        MaximumSolution:'峰值牵引工况',
        MaxBrakingSolution:'峰值制动工况',
    },
    //Components-result-Curve 无
    //Components-result-MapChart+NoiseMap+VnrChart 完成
    //Components-result-Voise 无
    MapChart:{
        fullScreen:'全屏查看',
        notFullScreen:'退出全屏',
    },
    //Components-result-Pdf 完成(Common+Prolist)
    Pdf:{
        sendRequest:'提交申请，使用该功能',
        noFile:'找不到此文件或此文件已损坏',
        noUrl:'pdf链接路径不存在',
        shareUrlSuccess:'创建分享链接成功',
        shareUrlFail:'创建分享链接失败',
        copyLink:'复制链接',
        shareUrlLoading:'正在生成分享链接',
        sourcePro:'来源产品',
        shareTitle:'分享内容',
        // copyTips:'复制这段文字用数智工程师APP获取，或者复制链接用浏览器获取。',
        copyTips:'复制链接用浏览器打开。',
        copyClip:'链接已复制到剪切板',
        cannotCopy:'暂不支持复制',
    },
    //Components-result-ResultNav 完成(Common+DesignIndex+routeMenu+ProList)
    //Components-result-ShareSidebar 完成(routeMenu+Pdf)
    //Components-result-Sidebar 完成(Common+routeMenu+Pdf)
    Sidebar:{
        downloadSuccess:'创建下载链接成功',
        downloadFail:'创建下载链接失败',
        downloadLoading:'正在生成下载链接',
        noSelectFile:'您尚未选中任何文件，请先选中文件或直接取消',
    },
    //? Components-enterprise 完成
    //Components-enterprise-BussinessNav 完成(UserNav+SubMenu)
    //Components-enterprise-Sidebar 完成(routeMenu)
    //*Components-enterprise-SubMenu 完成(SubMenu)
    //? Components-engineer 完成
    //Components-engineer-Contrast 完成(ProList+Common)
    Contrast:{
        selectContrast:'已选中对比产品',
        startContrast:'开始对比',
        selectRight:'选择右侧产品',
        select:'选中进行对比',
        noContrast:'暂无可对比产品',
        contrastResult:'产品对比结果',
        pro1:'产品1',
        pro2:'产品2',
        proName:'名称',
    },
    //Components-engineer-EngineerNav 完成(Common+UserRequest+UserLogin)
    EngineerNav:{
        fromRoute1:'在',
        fromRoute2:'页面搜索',
        limitExceed:'试用权限已用完，如需继续使用请联系客服或购买',
        trialDays1:'试用还有',
        trialDays2:'天到期',
        trialTimes1:'剩余',
        trialTimes2:'次',
    },
    //Components-engineer-ProList 完成(Common+routeMenu+EngineerIndex+OrderIndex+UserTrial)
    ProList:{
        computeStartTime:'开始时间',
        computeTime:'计算时间',
        updateTime:'更新时间',
        computeFinishTime:'结束时间',
        ratedPower:'额定功率',
        ratedSpeed:'额定转速',
        ratedTorque:'额定转矩',
        ratedEfficiency:'额定效率',
        insulationClass:'绝缘等级',
        createPerson:'创建人',
        shareStartTime:'分享时间',
        shareValid:'分享有效期',
        shareValidUtil:'有效期至',
        shareUnvalid:'已失效',
        clearUnvalid:'清除已过期分享',
        clear:'清除',
        proName:'产品名称',
        customerName:'客户名称',
        exportLoading:'正在导出',
        exportSuccess:'导出成功',
        exportFail:'导出失败',
        goOnlineMention:'是否立即前往EasiMotor Online 查看?',
        notGoOnline:'暂不前往',
        goOnline:'立即前往',
        delMention:'删除产品后所有文件夹下的该产品也会被删除，是否继续？',
        clearMention:'清除技术支持中的已失效产品，是否继续？',
        statusTitle:'状态提示',
        statusMention:'产品状态已发生变化，请刷新后重新操作',
        reName:'产品命名为',
        inputProName:'请输入新的产品名字',
        inputFileName:'请输入新的文件夹名字',
        proNameError1:'产品名称不能为空',
        proNameError2:'不能使用特殊字符，可包含()（）_-=.，最多包含50个字符',
        proNameError3:'已有相同名称的产品，请重新命名',
        proNameError4:'不能使用特殊字符，可包含()（）_-=.，最多包含100个字符',
        fileNameError1:'文件夹名称不能为空',
        fileNameError2:'同级文件夹名称不能重复',
        fileCustomer:'客户',
        fileMotorType:'电机类型',
        fileApplicationScene:'应用领域',
        addSubFile:'当前文件夹下新建',
        addRootFile:'在根目录下新建',
        addFile:'新建文件夹',
        alreadyAdd:'已添加',
        addSuccess:'已添加至文件夹分组',
        copySuccess:'复制成功',
        copySuccess1:'产品已复制成功并保存至草稿箱',
        delFromFile:'仅从文件夹移除',
        supportTitle:'技术支持请求',
        submitSupport:'确定发送请求',
        supportTitle1:'正在请求技术支持的产品：',
        supportTitle2:'',
        supportTitleTips:'我们会拷贝一份您的产品给技术支持团队，在技术支持团队解决完您的问题之前建议不要修改或者删除分享的产品数据。',
        supportSuccess:'您的问题已经成功反馈到技术支持!',
        allowCopyCheck:'允许拷贝当前产品的企业模板用于技术支持，允许后技术支持工程师方可正常打开产品',
        problem:'问题说明',
        inputProblem:'请输入问题说明',
        inputProblemTips:'请将您的问题尽可能描述清楚，有助于我们技术工程师快速解决问题',
        problemError:'问题说明不能为空',
        file:'文件',
        img:'图片',
        fileTips:'请上传与产品相关的文件',
        imgTips:'请上传与产品相关的图片',
    },
    //Components-engineer-Sidebar 完成(Common+ProList)
    engineerSidebar:{
        delFile:'删除文件夹',
        delFileTag1:'此文件夹为空，是否继续？',
        delFileTag2:'此文件夹不为空，删除文件夹后不会删除文件夹内的产品，该文件夹将被清除，是否继续？',
        delFileTag3:'此文件夹不为空，删除后将删除该文件夹下所有文件夹，不会删除文件夹内产品，是否继续？',
    },
    //*Components-engineer-SubMenu 完成(Common)
    SubMenu:{
        Member:'人员权限管理',
        Company:'企业信息',
        certified:'已认证',
        notCertified:'未认证',
        person:'个人账户',
        businessMaster:'企业主账户',
        businessSub:'企业子账户',
        business:'企业账户',
        expired:'已到期',
        myCalcTime:'我的核时',
        myPackage:'当前套餐',
        noPackage:'暂无可用套餐',
        toBuy:'前往购买',
        toUpdate:'前往升级',
        totalCalcTime:'总核时',
        usedCalcTime:'已用核时',
        avaliableCalcTime:'剩余',
    },
    //? Components-design 完成
    //Components-design-AnalyseSidebar 完成(importData+Common)
    //Components-design-ApplyConverse 完成(ApplyCurve)
    ApplyConverse:{
        applyAutoDesign:'自动设计电机指标',
    },
    //Components-design-DesignNav 完成(Common+EngineerNav+ProList)
    DesignNav:{
        system:'系统',
        enterprise:'企业',
        untitled:'未命名',
        help:'帮助文档',
        helpText:'帮助',
    },
    //Components-design-DesignSidebar 完成(Common+DesignIndex+importData+EngineerIndex)
    DesignSidebar:{
        moreOptions:'更多选项',
        syncSave:'数据同步保存到研发库，并覆盖研发库原有冲片',
        syncSave2:'该冲片将保存到研发库，并覆盖研发库原有冲片',
        coverLib:'覆盖研发库冲片',
        coverDone:'已覆盖',
        lamLib:'冲片库',
        lamCopy:'新建副本',
        lamCreate:'全新设计',
        collapse:'收起',
        expand:'展开',
        cost:'成本',
        performance:'性能',
        volume:'体积',
        weight:'重量',
        puchingNumError:'冲片极数不符合条件',
        negativeError:'不能为负数',
        cannotEmpty:'不能为空',
        mustNumber:'应为数字格式',
        mustNumber2:'应为数字格式',
        notRepeat:'不能重复',
        copy:'副本',
        none:'无',
        collapseCondition:'收起选项',
        expandCondition:'展开选项',
        customConditionName:'工况名称',
        addCustomTitle:'添加自定义工况',
        customConditionError:'自定义工况名称不能为空',
        delCustomTitle:'删除自定义工况',
        delCustomTips:'是否确定删除自定义工况',
    },
    //Components-design-LaminationEdit 无
    //Components-design-LaminationList 完成(Common+importData+OrderIndex+DesignIndex)
    LaminationList:{
        chooseLam:'选择冲片',
        noLamination:'暂无冲片',
        PLATFORM:'系统库',
        ENTERPRISE:'企业库',
        PERSON:'个人库',
        TEMPORARY:'研发库',
    },
    //Components-design-MaterialDrawer 完成(Common)
    MaterialDrawer:{
        silicon:'硅钢',
        magnetic:'磁钢',
        other:'其它',
        siliconSelect:'硅钢材料选择',
        magneticSelect:'磁钢材料选择',
        selectMaterial:'材料选择',
        selected:'已选材料',
        noMaterial:'暂无材料',
        searchByName:'按材料名称进行搜索',
        category:'类型',
        grade:'牌号',
        heatLevel:'耐热等级',
        name:'名称',
        sendBenefitMail:'提交申请,使用该功能',
        STANDARD:'系统库',
        USER:'用户库',
    },
    //Components-design-OptionSelect 完成(Common)
    OptionSelect:{
        inputNum:'请输入',
        inputText:'请输入',
    },
    //Components-design-RSDrawer 完成(Common+importData)

    //材料库Components-lib
    Lib:{
        navLib0:'材料库',
        navLib1:'磁钢材料',
        navLib2:'硅钢材料',
        navLib3:'其他材料',
        selectLib1:'磁钢材料库',
        selectLib2:'硅钢材料库',
        selectLib3:'其他材料库',
        otherMaterial1:'导电材料',
        otherMaterial2:'转轴材料',
        otherMaterial3:'机座材料',
        otherMaterial4:'绝缘材料',
        submitSelect:'确定选择',
        housingSelected:'已选机壳',

        name:'名称',
        materialName:'材料名称',
        libraryId:'库名称',
        category:'分类',
        categoryId:'材料分类',
        manufactureId:'原厂商',
        supplierName:'供应商',
        initPrice:'单价(元/千克)',
        initPriceUnit:'元',
        refTemprature:'参考温度',
        conductivity:'电导率',
        relativePermittivity:'相对介电常数',
        resTempCoeff:'电阻温度系数',
        relativePermeabilityGroup:'相对磁导率',
        linear:'线性',
        notLinear:'非线性',
        relativePermeCurve:'B-H曲线',
        relativePermeCurve2:'H-B曲线',
        magOrPer:'剩磁强度(Br)/内禀矫顽力(Mp)',
        magRetentivity:'剩磁强度',
        permMagnetizatn:'内禀矫顽力',
        magCoercivity:'磁矫顽力',
        magTempCoeff:'剩磁可逆温度系数',
        massDensity:'密度',
        specificHeat:'比热',
        thermalConducty:'导热率',
        youngsModule:'杨氏模量',
        poissonsRatio:'泊松比',
        magneticComputedDialog:'磁钢材料属性',
        coreLossTypeUnit:'硅钢片损耗系数',
        coreThickness:'单片磁钢厚度',
        coreLossTypeCurve:'铁芯损耗曲线',
        coreLossTypeCurve2:'B-P曲线',
        bhcF:'磁通密度[T]',
        bhcFUnit:'磁密单位(B)',
        tesla:'特斯拉',
        gauss:'高斯',
        bhcM:'磁场强度[A/m]',
        bhcMUnit:'磁场强度单位(H)',
        bhcMUnit1:'安/米',
        bhcMUnit2:'千安/米',
        bhcMUnit3:'奥斯特',
        bhcMUnit4:'千奥斯特',
        bpcP:'每单位功率损耗[W/kg]',
        bpcPUnit:'损耗单位(P)',

        bpcPUnit1:'瓦/米^3',
        bpcPUnit2:'千瓦/米^3',
        bpcPUnit3:'瓦/磅',
        bpcPUnit4:'瓦/千克',
        bhcFreq:'频率(Hz)',
        bhcThick:'厚度(mm)',


        unit:'单位',
        editUnit:'修改单位',

        select:'选择',
        selected:'已选材料',
        submitSelect:'确定选择',
        create:'新建',
        create2:'新建',
        copy:'复制',
        delete:'删除',
        material:'材料',
        deleteMaterial:'删除材料',
        cannotEmpty:'不能为空',
        copyDone:'材料已复制到用户库',

        editor:'编辑器',
        edit:'编辑',
        show:'查看',
        materialRepeat:'已有重名的材料',
        setCorrect:'请先正确设置',

        addPoint:'添加点',
        delPoint:'删除点',
        insertPoint:'插入点',
        sortPoints:'点排序',
        AddCurve:'添加',
        delCurve:'移除',
        importCurve:'导入',
        exportCurve:'导出',
        curveList:'曲线列表',
        temprature:'温度',
        frequency:'频率',
        temp:'温度',
        freq:'频率',
        centigrade:'摄氏温度',
        importTips:'这个操作将会删除所有已定义的数据，你确定要继续么？',
        curveTips:'具有相同温度和频率的曲线已存在',
        csvFile:'请上传csv格式的文件',
        BHcsv:'BH曲线.csv',
        BPcsv:'BP曲线.csv'
    },
}
export default Chinese