//en.js文件
const English ={
    Common:{
        login:'Login',
        tologin:'To Login',
        reLogin:'Login again',
        Logout:'Log out',
        out:'Exit',
        register:'Register',
        or:'Or',
        or2:' or ',
        confirm:'Confirm',
        yes:'Yes',
        no:'No',
        close:'Close',
        haveDone:'Done',
        edit1:'Edit',
        operate:'Edit',
        edit2:'Modify',
        edit3:'Modify ',
        del:'Delete',
        save:'Save',
        saveAs:'Save As',
        notsave:'No Save',
        all:'All',
        default:'Default',
        apply:'Apply',//用户试用+版本升级按钮
        buy:'To Buy',
        toPay:'To Pay',
        startDesign:'Design',
        validWarning:'Validation Warning',
        validWarningContact:'In case of difficulty, please contact customer service',
        validWarningLen:'A maximum of 20 error messages are displayed',
        submitApply:'Submit Application',
        freeTrial:'Trial',
        trial:'Trial',
        design:'Design',
        Back:'Back',
        benefitName:"Automatic analysis of multi-physical fields coupling for a single scheme",
        backHome:'Home',
        backEasimotor:'EasiMotor Online',
        backLamination:'Lamination Library',
        erCode:'Official Customer Service',
        tips:'Hint',
        limitTips:'Permission Hint',
        registerSuccess:'Register successfully!',
        submitSuccess:'Successfully submitted!',
        submitSuccess2:'Submit Application successfully!',
        saveSuccess:'Saved successfully!',
        importSuccess:'Imported successfully!',
        afterSubmitTips:"You will be contacted within 1 working day, please do not hesitate to answer.",
        know:'OK',
        link:'Link',
        submit:'Submit',
        ok:'OK',
        cancel:'Cancel',
        skip:'Skip',
        select:'Select',
        selectFile:'Select',
        resetSelect:'Reset',
        createLam:'Create',
        reset:'Reset',
        next:'Next',
        prev:'Previous',
        selectSlot:'Select Slot',
        goTo:'Go To',
        search:'Search',
        loading:'Loading',
        nomore:'No more',
        haveAccount:'Have account?',
        nodata:'No Data',
        showType1:'Display Chart',
        showType2:'Display List',
        Overview:'Overview',
        Account:'Account Settings',
        info:'Account Info',
        security:'Account Security',
        Order:'Order Management',
        onlineModel:'Online Model',
        createProDesign:'Create new product design',
        createPro:'Create new product',
        customStructure:'Custom Housing',
        matchHousingdia:'Match Lamination Outer Diameter',
        customStructureLib:'Custom Housing Library',
        addCustomStructure:'Add Custom Housing',
        inputHousingName:'Enter the housing name',
        housingOuterDia:'Outer Diameter',
        housingInnerDia:'Inner Diameter',
        saveHousingLib:'Save To Housing Library',
        reselectHousing:'Reselect Housing Library',
        selectHousing:'Select Housing',
        autoCalculate:'Automatic Calculation',
        autoMatch:'Auto Match',
        import:'Import',
        importLam:'Import',
        importLamination:'Import Lamination',
        importRotor:'Import Rotor DXF File ',
        uploadRotor:'Select rotor DXF file to upload',
        magnetGuide:'Magnet Region Setting',
        magnetGuideSub1:'Please click on the "Select" button to activate the "Magnet" region selection.',
        magnetGuideSub2:'Click on any region of the DXF model to set it as the "Magnet" region.',
        magnetGuideSub3:'Please click on the "Confirm" button to exit the "Magnets" region.',
        magnetGuideSub4:'Click on the selected "Magnet" region. Currently only the "Magnet" region requires detailed settings for individual region.',
        magnetGuideSub5:'After all the data settings in the table are completed, the "Magnet" region is completed',
        guideSkipBtn:'I already know, skip.',
        guideDoneBtn:'Use Now',
        importRotorSub:'Please make sure that the imported Dxf file contains only the rotor.',
        importLaminationSub:'Import laminations to Virtual Engineer in the following ways',
        importLaminationMotorType:'lease select suitable motor type for the lamination, and then this lamination can be filtered out by motor type later.',
        analyseSub:'Select the process to be analyzed for the initial scheme design.',
        housingParameter:'Housing Parameters',
        AnalysisProcess:'Analysis Process',
        ResultFile:'Result File',
        engineer:'Virtual Engineer',
        library:'Library',
        goods:'Price',
        chooseSku:'Select the package you need',
        viewGoods:'View Function Details',
        packageTips:'The package does not distinguish between motor types, now we support the following motor types: squirrel-cage induction motor, squirrel-cage induction motor(variable frequency), permanent magnet synchronous motor and brushless permanent magnet direct current motors.',
        packageName:'One Button Multi-physics',
        byFree:'(Free Trial)',
        byTimes:'(By Times)',
        byMonth:'(By Months)',
        byYear:'(By Years)',
        tolibrary:'Back to Library',
        ProFile:'Product folder',
        readDone:'I have read and agree',
        pleaseRead:'Please read all',
        policy1:' User Agreement',
        policy2:' Privacy Policy',
        subTitle:'One account with access to all MAXWELL products',
        subTitleTips:'EasiMotor Online, Virtual Engineer,etc.',
        dxfImport:'Dxf File Import',
        dxfImportSub:'Only support uploading rotor Dxf files',
        reselectDxf:"There are no suitable stator laminations now, please select it again.",
        rotorPosition:'Rotor Position',
        rotorStructure:'Rotor Structure',
        innerRotor:'Inner Rotor',
        outerRotor:'Outer Rotor',
        rotor:'Rotor',
        stator:'Stator',
        model:'Model',
        geometry:'Geometry',
        parameterErr:'Please set all parameter values correctly first.',
        saveStructure:'Save Housing',
        structureName:'Housing Name',
        saveTo:'Save to',
        saveLam:'Save Lamination',
        example:'Diagram',
        uploadPic:'Upload Image',
        useDefautImg:"Don't upload, use the default diagram.",
        defautImg:'Default Diagram',
        uploadPicSub:'Upload a picture as housing avatar, recommended size is 200*200px',
        setRegion:'Select Region',
        regionType:'Region Type',
        region:'Region ',
        region2:' Region ',
        haveSelect1:"Please set parameters for ",
        haveSelect2:" magnet!",
        haveSelect3:" magnets!",
        allSelect:"All regions have been completed.",
        selectRegion:'select',
        cofirmRegion:'Confirm',
        addline:'Add Row',
        deleteline:'Delete Row',
        saveLamSub:"Your lamination has been successfully imported into the lamination library, you can choose to continue importing, or go to Product Library.",
        saveLamSub2:"Your lamination has been successfully imported into the lamination library, you can choose to continue importing, or go to EasiMotor Online Lamination Library.",
        continue:"Continue",
        setRotorModel:'Please set the rotor model region first.',
        setRegionFirst1:'Please set the parameters of ',
        setRegionFirst2:' region first.',
        setRegionType:'Please set all region types first.',
        setRotorGeometry:'Please set the rotor geometry data first.',
        setStator:'Please set the stator parameters first.',
        setCycles:'The ratio of pole number to cycle number should be a positive integer.',
        saveLamLib:'Save to the lamination library?',
        quitEdit:'Still to quit editing?',
        notSaveMen:"If you don't save, your changes will be lost.",
        cyclePlaceholder:'Activate after cycle entry',
        selectStator:'Please select stator type',
        custom:'Custom',
        editPara:'Edit',
        importTip:'This operation will overwrite all the defined data, are you sure to continue?',
        notFound:'404 Not Found',
        edit:{
            export:'Export',
            importData:'Data Import',
            changeMode:'Switch Mode',
            exportOnline:'To Online',
            exportOnlineTip:'Export To Online',
            exportLamToLib:'Export Lamination To Library',
            exportHousingToLib:'Export Housing To Library',
            exportLam:'To Library',
            exportLamTip:'Export To Lamination Library',
            exportEasiMotorOnline:'Export To EasiMotor Online',
            deletePro:'Delete',
            reName:'Rename',
            reDesign:'Edit',
            addFile:'Add to File',
            contrast:'Compare',
            support:'Support',
            copy:'Copy',
        },
        userRegister: {
            realName: "Your Name", //姓名
            userName:'Account',//用户名
            password:'Password',
            company: "Company Name", //单位名称
            college: "College and University Name", //大学名称
            position: "Your Position", //岗位或职务
            profession: "Your Department Name", //所属院系专业
            provinceArea:'Provinces, Cities and Districts',
            country:'Country',
            province:'Province',
            city:'City',
            area:'District',
            address:'Full Address',
            socialCreditCode:'Uniform social credit code',
            staffNumber: "Number of Employees", //企业人员规模
            teamNumber: "Number of R&D Team", //研发团队规模
            annualOutput: "Annual Turnover", //企业年产值
            phone:'Phone',
            unitNature:"Nature of Your Company",
            unitNature1:'Motor Manufacturing Enterprise',
            unitNature2:'Motor Use Enterprise',
            unitNature3:'Motor Supplier',
            unitNature4:'Colleges and Universities',
            unitNature5:'Research Institution',
            smsCode:'Code',
            mail:'Email',
            refereePhone:"Referrer's Phone Number (optional)",
            confirmPassword:'Confirmation Password',
            haveAccount:'Already have an account?',
        },
        valid: {
            pleaseInput:'Please Enter ',
            pleaseSelect:'Please Select',
            inputOrSelect:'Select or Enter',
            submitSelect:'Select Done',
            realName: "Enter Your Name", //姓名
            userName:"Enter account",
            password:"Enter password",
            company: "Enter Company Name", //单位名称
            college: "Enter College Name", //学校名称
            position: "Enter Your Position", //岗位或职务
            profession: "Enter Your Department Name", //所属院系专业
            provinceArea:'Enter Provinces, cities and districts',
            country:'Enter Country',
            address:'Enter Full Address',
            socialCreditCode:'Enter Uniform Social Credit Code',
            socialCreditCodeFormat:'Enter the correct Uniform social credit code',
            phone: "Enter phone number",
            mail:  "Enter email address",
            smsCode: "Enter code",
            smsCodeError: "Enter the correct verification code",
            phoneFormat: "Enter the correct phone number",
            refereePhoneFormat: "Enter the correct phone number (or leave it blank)",
            mailNone: "E-mail cannot be empty",
            mailFormat: "Enter the correct Email address.",
            mailFormat2: "Enter your educational email address (.edu.cn)",
            mailFormat3: "Enter your business email address",
            unitNature: "Select Nature of Your Company",
            staffNumber: "Enter Number of Employees", //企业人员规模
            teamNumber: "Enter Number of R&D Team", //研发团队规模
            annualOutput: "Enter Annual Turnover", //企业年产值
            passwordAgain:'Enter your confirmation password',
            differPassword:'Password Inconsistency',
            userNameFormat:"Consists of letters, numbers and underlines, starting with a letter",
            passwordFormat:"6-18 digits, consisting of upper case letters, lower case letters and numbers",
            newPasswordFormat:'Please enter 8-32 digits letters or numbers',
            getSmsCode:'Get Code',
            getSmsCode1:'Get Code',
            getSmsCode2:'Get Code',
            resend:'Resend',
            motorType:'Motor types of motor products(Multiple Choices)',
            motorTypeInput: "Enter the motor type",
            otherMotorType:'Other motor type',
            applicationScene:'Application areas of motor products(Multiple Choices)',
            applicationSceneInput:"Enter the application areas",
            otherapplicationScene:'Other application area',
            productType:'Product types of your company(Multiple Choices)',
            productTypeInput:"Enter the type of matching products",
            otherproductType:'Other type of matching products',
        },
    },
    //*  route-menu+store-index完成
    routeMenu:{
        result:'Output Result',
        Success:'Success',
        Fail:'Failed',
        Computing:'Designing',
        Draft:'Draft',
        folder:'folder',
        ImportApply:"Application area",
        applyParam:'Application Area Parameters',
        ImportData:"Input Data",
        Report:"Report",
        Craft:"Process File",
        Winding:"Winding Diagram",
        Drawing:"Drawings",
        Bom:"BOM",
        example:'Demo',
        Member:'Member Management',
        memberControl:'Member',
        Person:'Person',
        Group:'Group',
        ResourceControl:"Resource",
        Resource:"Permission",
        Account:'Account Settings',
        UserSettingOverview:'Overview',
        AccountInfo:'Account Information',
        AccountSecurity:'Account Security',
        Order:'Order Management',
        engineer:'Virtual Engineer',
        messageProduct:"Not selected any products",
        messageDesign:"Product is not saved, please design again",
        messageContrast:"Please reselect products to compare",
        cannotTrial:"You are unable to apply for a free trial currently.",
        loginAgain:"Your login information is no longer valid, please log in again",
        toCertificate:"Please fill in the authentication information first",
    },
    //? app完成(common)
    //? view-userSetting 完成
    //? views-user-UserSettingIndex(无)
    //? view-share(无)
    //? view-ResultIndex(无)
    //? view-MemberIndex
    MemberIndex:{
        notEnterprise:'This account is not a business account',
    },
    //* views-Home 完成
    Home:{
        footer:{
            Hotline:'Hotline',
            HotlineNum:'0086-571-85464125',
            PTC:'Professional Technical Consultation',
            CPI:'Comprehensive Product Introduction',
            GS:'Good Solution',
            SCCA:'Successful Customer Case Analysis',
            PnS:'Product and Service',
            CS:'Consulting Service',
            CS1:'Motor and Electromechanical System Consulting',
            CS2:'Power Supply Design and Simulation',
            CS3:'Electrical System Design',
            SnS:'Support and Study',
            SnS1:'Online Help',
            SnS2:'Online Community',
            Company:'Company',
            Company1:'About Us',
            Company2:'Talents',
            Company3:'Contact',
            Company4:'Security Assurance',
            Company5:'Enter Official Website',
            Focus:'Focus Us',
            WXOfficial:'Official WeChat',
            WXMotor:'Motor Seminar',
            CopyRight1:'Hangzhou Easitech Corporation All Right Reserved',
            CopyRight2:'Zhejiang ICP Security No.10046028-4',
            CopyRight3:' Zhejiang Public Network Security No.33010802009522',
        }
    },
    //? views-EngineerIndex完成(common+routeMenu)
    EngineerIndex:{
        designStatus:'Design Status',
        applicationScene:'Application Area',
        motorTypeCode:'Motor Type',
        motorStructureCode:'Housing',
        selectapplicationScene:"Select Application Area",
        selectmotorTypeCode:"Select Motor Type",
        selectmotorStructureCode:'Select Housing',
        companyStructure:'Enterprise Housing',
        systemStructure:'System Housing',
        noStructure:'No Housing Data',
        noEnterpriseStructure:'No data available at this time, available after upgrading to an enterprise account.',
        SatisfiedAll:'Satisfy All Constraints',
        SatisfiedPart:'Satisfy Some of Constraints',
        createDesign:'Create new design',
        systemLib:'System Template Library',
        systemStandardLib:'System Standard Library',
        companyLib:'Enterprise Template Library',
        companyStandardLib:'Enterprise Standard Library',
        customHousing:"Personal Library",
        housingBasicInfo:'Basic Information',
        housingStructureInfo:'Housing Information',
        housingUsedPro:'Used Housing Product',
        proHousing:"Created Product Housing",
        housingDetail:'View Detail',
        housingCopy:'New Copy',
        onlineToVirtual:'Your project is being opened and created by "Virtual Engineer".',
    },
    //? views-DesignIndex完成(common+routeMenu+ProList)
    DesignIndex:{
        reference:'Default',
        applyParams:'Application Area',
        performanceParams:'Performance',
        inputPunchingSheetName:"Enter the lamination name.",

        punchingNameError1:'Lamination name cannot be empty.',
        housingNameError1:'Housing name cannot be empty.',
        punchingNameError2:'Special characters cannot be used, maximum 50 characters',

        applyConverse:'Indicator Conversion',
        closeApplyParam:'Cancel and close',
        saveApplyParam:'Close after saving',
        noBenefit:"You are not using this scheme for product design at the moment, if you want to use it properly, you can buy",
        orContact:'or contact us.',
        noBenefitMetion:"You don't have permission to use this function now, please submit an upgrade request if you wish to use it.",
        noDownloadMetion:'You do not have permission to use the download function, please purchase the package first before using.',
        noShareMetion:'You do not have permission to use the share function, please purchase the package first before ubsing.',
        subAccountMention:'Current account can not be purchased, if you need to use please notify the business master to purchase.',
        noCalcTimeMetion:'You have run out of all core hours, please submit an upgrade request if you wish to use it.',
        cancelDesign:'Cancel',
        submitDesign:'Next',
        toDetail:'View Detail',
        noSupport:"This feature isn't supported in the current version.",
        toEasimotorOnline:"You can go to Easimotor Online on the web and create a new lamination for this motor type.",
        tableData:{
            title1:'Corner Point Power(kW)',
            title2:'Corner Point Speed(rpm)',
            title3:'Corner Point Torque(Nm)',
            title4:'Minimum Speed(rpm)',
            title5:'Maximum Speed(rpm)',
        },

        paramsError1:'Numeric values cannot be null.',
        selectScheme:'Select Design Solution',
        saveToDraft:'Saved to the draft box',
        saveToDesign:'Saved and start design',

    },
    //? views-user 完成
    //views-user-UserCertificate 已完成(common)
    UserCertificate:{

        title:'Fill in Certification Information',
        subTitle:'To ensure that you can use Maxwell product properly, please confirm the following information and complete the review.',
        wechatTipsTop:"After confirming the above information, you need to WeChat scan the above QR code, plus the official customer service of the Maxwell's enterprise WeChat friends, after passing the application and receiving the SMS notification, you can re-login to start the experience!",
        continue:' continue browsing!',
    },
    //views-user-UserLogin 已完成(common)
    UserLogin:{
        trial:'Trial application is now open, to get 14 days free trial quickly!',
        trial2:'Register now for 5 free calculations',
        step1:'Login Account',
        step2:'Submit Application',
        step3:'Permission will be granted after approval',
        light1:'No need to learn complex software',
        light2:'No need to buy expensive software',
        light3:'No need to spend days waiting for calculation results',
        light4:'Electromagnetism, stress, temperature rise and control are all covered',
        light5:'You can calculate scheme by cell phone, PAD or computer at any time',
        light6:'Three minutes of large-scale cloud computing to complete the whole process',
        light7:'Automatically generate optimized design reports, drawings, BOMs and process documents',
        contact:'You can contact us by the following ways:',
        scanLeft:'Official WeChat customer service: scan the left QR code',
        scanTop:'Official WeChat customer service: Scan the above QR code',
        Hotline:'Hotline：0571-85464125/6/7',
        Mail:'Email：Marketing@easi-tech.com',
        LoginTitle:'Account Password Login',
        noAccount:'No account?',
        forget:'Forgot your password?',
        register:' To Register',
        browser:'The following browsers are recommended',
        loginSuccess:'Login successfully',
    },
    //views-user-UserRegister 已完成(common)
    //views-user-UserOntrial 无
    //views-user-UserPay 已完成(common)
    UserPay:{
        step1:{
            title:'Confirm Order Information',
            name:'Name',
            price:'Unit Price',
            num:'Quantity',
            totalAmount:'Total',
            payAmount:'Amount Due',
            submit:'Submit Order',
            tips:'Please confirm the above information before payment, if you have any questions, please do not hesitate to contact us!',
        },
        step2:{
            title:'Cashier',
            orderDetail:'Order Details',
            details:'Details',
            times:'Number of Calculations',
            expired:'Valid Time',
            expiredTo:'Expiration Date',
            monthExpired1:'Valid for ',
            monthExpired:' months',
            yearExpired:' years',
            subTitle:'Your order has been submitted successfully! You can pay now!',
            orderNo:'Order No.',
            payAmountFinal:'Actual Payment',
            select:'Payment Method',
            AliPay:'Alipay',
            WeChat:'WeChat',
        },
        done:{
            paySuccess:'Pay successfully',
            payFail:'Transaction Failed',
            ifContact1:'If you have any questions or need to customize,',
            ifContact2:'If you need the invoice,',
            contact:'Please contact our sales team.',
        }
    },
    //* views-user-UserRequest 已完成(common)
    UserRequest:{
        price:'Price',
        five:'Free 5 times',
        tips1:'Please be sure to add the official WeChat customer service!',
        tips2:'Successful application can enjoy 14 days of validity, 5 times the calculation of permissions.',
    },
    //views-user-UserTrial(common)
    UserTrial:{
        free:'Free',
        trial:' Apply for Trial',
        trial1:'Apply for Free Trial',
        trial2:'Free',
        trial3:'Trial Apply Again',
        submitSuccess1:'Submit Application successfully!',
        submitSuccess2:'Submit Application successfully!',
        uploadSuccess:'Upload successful!',
        afterSubmit1:'Submitted successfully ,you are under review......',
        afterSubmit2:'You need to scan the QR code on the left on WeChat to keep abreast of the application progress!',
        afterSubmit3:'After passing the application and receiving the SMS notification,you can get the "Product Manual", and you can log in to try.',
        failSubmit1:'Failed to submit trial application!',
        failSubmit2:'Official customer service will contact you as soon as possible to help you trial application success.',
        failSubmit3:'If you have not added official customer service friends, please add them as soon as possible.',
        failSubmit4:'You can resubmit',
        imgError1:'Only supports uploading images in JPG/jpeg/png format!',
        imgError2:'Uploaded image size cannot exceed 10MB!',
        fileError:'Upload file size cannot exceed 20MB!',
        csvFileError:'Only supports uploading files in csv format!',
        xlsFileError:'Only supports uploading files in xls or xlsx format!',
        xlsUploadErr:'Data import failed, data matching exception.',
        license:'Enterprise business license copy picture',
        imgTips1:'Support images in jpg/jpeg/png format, size ≤10M',
        imgTips2:'Please do not cover or blur, to ensure that the information is clearly visible"',
        imgTips3:'Please provide the scanned or stamped copy of the original three-in-one business license.',
        imgTips4:'Please provide the business license within the validity period, if the letter expires, it will not be passed.',
        example:'Example Diagram',
        selectImg:'Selected Picture',
        selectDxf:'Selected Dxf File',
        uploadLicense:'Upload a copy of business license',
        staffNumber:' Number of Employees',
        teamNumber:' Number of R&D Team',
        annualOutput:' Annual Turnover',
        select:'Please Select',
        needFollow:'Do you need a further introduction or explanation in the near future?',
        needApp:'Do you need to try it on the mobile app?',
        proFunction:"The features you'd most like to experience with Virtual Engineer (multiple choices)",
        subTop:'Your Virtual Engineer trial has expired, please fill out your trial experience feedback form for a chance to get another trial!',
        afterTrialFavorite:'Your Favorite Features (Multiple Selection)',
        afterTrialNotGood:'Which features need to be improved(Multiple Selection)',
        afterTrialBuy:'After the trial, does your company have any plan to purchase Virtual Engineer?',
        addFunction:'After the trial, what features do you think need to be added to Virtual Engineer?',
        otherFunction:'Other functions',
        afterTrialWanted:'Please input other functions you would like to see',
        valuation:'Please write down your overall evaluation of the trial of Virtual Engineer!',
        afterTrialComment:'Please enter your trial evaluation (recommended no less than 20 words)',
    },
    //? view-userSetting 完成
    //views-user-UserSettingIndex(无)
    //views-user-UserSetting-Overview完成(common)
    Overview:{
        benefit:'Rights and Interests',
        benefitName:'Right Name',
        operatorType:'Operation',
        benefitNum:'Amount',
        createTime:'Time',
        history:'Records',
        noBenefit:'No Rights and Interests',
        accountInfo:'Account Information',
        accountType:'Account Nature',
        buyOnce:'Buy',
        version:'Version',
        versionInfo:'Multi-scheme optimization, enterprise customization',
        operatorType1:'Get',
        operatorType2:'Used',
        operatorType3:'Returned',
        operatorType4:'Expired',
        infinite:'Unlimited Times',
    },
    //views-user-UserSetting-AccountInfo完成(common)
    AccountInfo:{
        motorTypeTag:'Motor Types',
        applicationSceneTag:'Application Areas',
        no:'Null',
    },
    //views-user-UserSetting-AccountSecurity完成(SubMenu+common)
    AccountSecurity:{
        password:'Password',
        passwordInfo:'Used to protect account information and login security',
        tel:'Phone',
        telInfo:'Used to log in your account, resetting your password, or other security verifications',
        changePassword:'Set Password',
        changeTel:'Set Phone',
        oldPassword:'Old',
        newPassword:'New',
        newTel:'New',
        newPassword2:'Confirm',
        fromType1:'Modify it via SMS verification code.',
        fromType2:'Modify it via password',
        oldNewPassError:'The new password is the same as the old one.',
        oldNewTelError:'New cell phone number is the same as the old one.',
        passwordSuccess:'Modify successfully',
        telSuccess:'Modify successfully',
    },
    //views-user-UserSetting-OrderIndex完成(common)
    OrderIndex:{
        search:'Enter Order Name',
        createTime:'Create Time',
        expiredTime:'Expiration Date',
        to:'to',
        startTime:'Start Time',
        finishTime:'End Time',
        show:'Show',
        pageNo:'Pagesize',
        noOrder:'No Order',
        skuname:'Name',
        skuoption:'Option',
        skunum:'Amount',
        skuamount:'Price',
        actualPay:'Actual Payment',
        skustatus:'Status',
        skueditor:'Edit',
        orderNo:'Order No.',
        copy:'Copy Order No.',
        remain:'remain',
        clipboard:'Order number has been copied to clipboard',
        delOrder:'Delete',
        cancelOrder:'Cancel',
        selectAll:'Select All',
        select1:'Selected',
        select2:'',
        select3:'',
        pageSize1:'Total',
        pageSize2:'',
        delTips:'Data cannot be recovered after deleting, continue or not?',
        cancelTips:'Order is canceling, continue or not?',
        clipboardError:'Not Allowed Copy',
        allOrder:'All Orders',
        orderStatusTo:'Pending',
        orderStatusSuccess:'Succeeded',
        orderStatusFail:'Failed',
        orderStatusCancel:'Canceled',
    },
    Goods:{
        coreFunction:'Core Functional Modules',
        coreFunction1:'Rapid Design of Motor Products and Output Results',
        coreFunction2:'Automatic Multi-objective Optimization of Motors',
        coreFunction3:'Automatic Update of Design Schemes Based on Material Prices',
        coreFunction4:'Automatic Multi-field Coupling Analysis and Calculation of Motor',
        coreFunction5:'Automatic Virtual Experiments for Motor Performance',
        coreFunction6:'Automatic Generation of Motor MAP',
        coreFunction7:'Automatic Generation of Demagnetization Analysis Report',
        coreFunction8:'Automatic Generation of Thermal Analysis Report',
        coreFunction9:'Automatic Generation of Stress Analysis Report',
        coreFunction10:'Automatic Generation of Vibration and Noise Analysis Report',
        coreFunction11:'Automatic Generation of Cost Analysis Report',
        coreFunction12:'Automatic Generation of Complete Process Document',
        coreFunction13:'Automatic Generation of Winding Diagram',
        coreFunction14:'Automatic Generation of Complete Drawing',
        coreFunction15:'Automatic Generation of BOM',
        coreFunction16:'Automatic Generation of Sales Support Data',
        libraryFunction:'Library Functionality Options',
        libraryFunction1:'Enterprise Product Library Management',
        libraryFunction2:'Customized Enterprise Product Database, Lamination Library',
        libraryFunction3:'Lamination, Component and Material Library',
        libraryFunction4:'Industry Standard Library',
        outputProject:'Output Project',
        outputProject1:'Scheme Data Comparison Report',
        outputProject2:'Optimized Scheme Report',
        outputProject3:'Basic Field Diagram Analysis Report of Motor',
        outputProject4:'Comparison Analysis Report of Electromagnetic Performance',
        outputProject5:'MAP Analysis Report',
        outputProject6:'Demagnetization Resistance Analysis Report',
        outputProject7:'Thermal Analysis Report',
        outputProject8:'Stress Analysis Report',
        outputProject9:'Vibration and Noise Analysis Report',
        outputProject10:'Cost Analysis Report',
        outputProject11:'Result Download and Sharing Function',
        industryChain:'Industry Chain Collaboration Function',
        industryChain1:'Automatic Generation of 3D Model',
        industryChain2:'Automatic Generation of Motor Digital Prototype Virtual Model',
        industryChain3:'Automatic Generation of Equipment Processing Codes',
        industryChain4:'Data Intercommunication and Quotation with Customers and Suppliers',
    },
   //? view-result 完成
    //views-result-bom(无)
    //views-result-craft(无)
    //views-result-drawing(无)
    //views-result-winding(无)
    //*views-result-importApplyScene完成(DesignIndex+Common)
    //*views-result-importData完成
    importData:{
        basic:'Requirements',
        restrictions:'Constraints',
        chooseLamination:'Lamination',
        lamination:{
            punchingName:'Lamination Name',
            motorType:'Motor Type',
            statorExternalDiameter:"Stator Outer Diameter",
            statorInternalDiameter:"Stator Inner Diameter",
            rotorExternalDiameter:"Rotor Outer Diameter",
            rotorInternalDiameter:"Rotor Inner Diameter",
            rotorExternalInner:'The outer diameter must be larger than the inner diameter',
            number:"Poles",
            statorSlotNum:"Stator Slots",
            slotType:"Slot Type",
            source:"Source",
            statorSlot:'Stator Slot',
            rotor:'Rotor',
            rotorSlot:'Rotor Slot',
            stator:'Stator',
        },
        optimizeTarget:'Objectives',
        scanVariables2:'Boundary',
        schemeTitle:'Design',
        scanVariables:'Initial',
        advanced:'Advanced Settings',
        analysisProcess:'Analysis Process',
        analysisError:'Please check at least one analysis process',
    },
    //views-result-report完成(routeMenu)
    report:{
        schemeDesignType:'Introduction',
        refreshImgTip:'The data is not loaded, you can try to refresh it manually',
        noDataTip:'No data',
    },
    //? view-member 完成
    //views-member-AdminAddPerson完成(Common)
    AdminAddPerson:{
        createPerson:'Create',
        userName:'Account',
        userNameValid:'Please enter Chinese, letters, numbers, ., _ or -, maximum 64 characters',
        realName:'Name',
        realNameValid:'Please enter Chinese, letters, numbers, ., _ or -, maximum 32 characters',
        addPerson:'Add',
        password:'Password',
        passwordValid:'8-32 alphanumeric characters',
        passError1:'Enter Password',
        passError2:'8-32 alphanumeric characters',
        userNameError1:'Cannot be empty',
        userNameError2:'Please enter Chinese, letters, numbers, ., _ or -, maximum 64 characters',
        realNameError1:'Cannot be empty',
        realNameError2:'Please enter Chinese, letters, numbers, ., _ or -, maximum 32 characters',
        addError:'Please add at least one user',
    },
    //views-member-AdminPerson完成(Common+AdminAddPerson+OrderIndex)
    AdminPerson:{
        person:'Sub-account',
        createPerson:'Create',
        password:'Password',
        lastTime:'Last Operation Time',
        delPerson:'Delete',
        delPersonError:'Only delete the user, the data of the lamination created by the user will not be cleared, do you want to continue?',
        resetPass:'Reset',
    },
    //views-member-MemberOverview(Common+Overview)
    MemberOverview:{
        basicData:'Basic Data',
        subAccountNum:'User number',
        projectNum:'Project number',
        laminationNum:'Lamination number',
        calcTime:'Core hours',
        accountIdentity:'Account identity',
        hour:'Hour',
    },
    //? view-engineer 无
    //? Components-user 完成
    //User-CountEnd 无
    //User-UserNav 完成
    UserNav:{
        Pay:'Payment Center',
        design:'Design Motor',
    },
    //User-Sidebar 已完成(common)
    //User-Tag 已完成
    Tag:{
        CommonVIP:'General VIP',
        TrialVIP:'Trial VIP',
        EnterpriseVIP:'Enterprise VIP',
    },
    //? Components-result 完成
    //* Components-result-ApplyCurve 完成
    ApplyCurve:{
        rotateSpeed:'Speed[rpm]',
        torque:'Torque[Nm]',
        curveTitle:'Characteristic Curves',
        RatedSolution:'Rated',
        MaximumSolution:'Peak Traction',
        MaxBrakingSolution:'Peak Braking',
    },
    //Components-result-Curve 无
    //Components-result-MapChart+NoiseMap+VnrChart 完成
    //Components-result-Voise 无
    MapChart:{
        fullScreen:'Full screen',
        notFullScreen:'Exit',
    },
    //Components-result-Pdf 完成(Common)
    Pdf:{
        sendRequest:'Upgrade',
        noFile:'This file cannot be found or is corrupted',
        noUrl:'The pdf link path does not exist',
        shareUrlSuccess:'The sharing link was created',
        shareUrlFail:'Failed to create a shared link',
        copyLink:'Copy Link',
        shareUrlLoading:"Sharing link is being generated",
        sourcePro:"Source Products",
        shareTitle:"Share Content",
        // copyTips:"Copy this text to open it with the Virtual Engineer app, or copy the link to open it with your browser.",
        copyTips:"Copy  the link to open it with your browser.",
        copyClip:"Link has copied to clipboard",
        cannotCopy:'Copying is not supported now',
    },
    //Components-result-ResultNav 完成(Common+DesignIndex+routeMenu+ProList)
    //Components-result-ShareSidebar 完成(routeMenu+Pdf)
    //Components-result-Sidebar 完成(Common+routeMenu+Pdf)
    Sidebar:{
        downloadSuccess:'The download link was created',
        downloadFail:'Failed to create download link',
        downloadLoading:'Download link is being generated',
        noSelectFile:'You have not selected any files, please select Chinese first or cancel directly',
    },
    //? Components-enterprise 完成
    //Components-enterprise-BussinessNav 完成(UserNav+SubMenu)
    //Components-enterprise-Sidebar 完成(routeMenu)
    //* Components-enterprise-SubMenu 完成(SubMenu)
    //? Components-engineer 完成
    //Components-engineer-Contrast 完成(ProList+Common)
    Contrast:{
        selectContrast:'Product Comparison',
        startContrast:'Compare',
        selectRight:'Please Select',
        select:'Select to Compare',
        noContrast:"Can't find the comparable product with the same application area, same motor type, and same housing conditions.",
        contrastResult:'Comparison Results',
        pro1:'No.1 Product',
        pro2:'No.2 Product',
        proName:'Product Name',
    },
    //Components-engineer-EngineerNav 完成(Common+UserRequest+UserLogin)
    EngineerNav:{
        fromRoute1:'Search in ',
        fromRoute2:'',
        limitExceed:'Trial right has been used up, please contact customer service or purchase.',
        trialDays1:'Remain',
        trialDays2:'days',
        trialTimes1:'Remain',
        trialTimes2:' times',
    },
    //Components-engineer-ProList 完成(Common+routeMenu+EngineerIndex+OrderIndex+UserTrial)
    ProList:{
        computeStartTime:'Start Time',
        computeTime:'Calculation Time',
        updateTime:'Update Time',
        computeFinishTime:'End Time',
        ratedPower:'Rated Power',
        ratedSpeed:'Rated Speed',
        ratedTorque:'Rated Torque',
        ratedEfficiency:'Rated Efficiency',
        insulationClass:'Insulation Class',
        createPerson:'Creator',
        shareValid:'Share Expiration',
        shareStartTime:'Share Time',
        shareValidUtil:'',
        shareUnvalid:'Expired',
        clearUnvalid:'Clear Expired',
        clear:'Clear',
        proName:'Product Name',
        customerName:'Customer Name',
        exportLoading:'Exporting',
        exportSuccess:'Export successfully',
        exportFail:'Failed to export',
        goOnlineMention:'Are you going to EasiMotor Online to view immediately?',
        notGoOnline:'Cancel',
        goOnline:'Go Now',
        delMention:'After deleting, this product under all folders will also be deleted, are you sure to continue?',
        clearMention:'Clear expired products from Technical Support, are you sure to continue?',
        statusTitle:'Status Tip',
        statusMention:'The status of the product has changed, please refresh and do it again',
        reName:'Product Name',
        inputProName:'Enter product name.',
        inputFileName:'Enter new product name.',
        proNameError1:'The product name cannot be empty',
        proNameError2:"Special characters cannot be used except for '()（）_-=.', maximum 50 characters",
        proNameError4:"Special characters cannot be used except for '()（）_-=.', maximum 100 characters",
        proNameError3:'It already has the same product name, please rename.',
        fileNameError1:'Folder name cannot be empty',
        fileNameError2:'Folder names cannot be duplicated at the same level',
        fileCustomer:'Customer',
        fileMotorType:'Motor Type',
        fileApplicationScene:'Application area',
        addSubFile:'New in the current folder',
        addRootFile:'New in root folder',
        addFile:'New Folder',
        alreadyAdd:'Added',
        addSuccess:'Added to Folder Group',
        copySuccess:'Copy successfully',
        copySuccess1:"Copied successfully and saved to the draft box.",
        delFromFile:'Remove from folder only',
        supportTitle:'Technical Support',
        submitSupport:'Send Request',
        supportTitle1:'Technical support is being requested for : ',
        supportTitle2:'',
        supportTitleTips:'We will make a copy of your product to the technical support team, please do not modify or delete the product data until we have resolved your problem.',
        supportSuccess:"Your question has been successfully fed back to technical support!",
        allowCopyCheck:'The enterprise template of the current product is allowed to be copied, in order to open the product normally.',
        problem:'Problem Description',
        inputProblemTips:'Please describe your problem as clearly as possible to solve the problem quickly',
        inputProblem:"Enter the problem description.",
        problemError:'The problem description cannot be empty',
        file:'File',
        img:'Picture',
        fileTips:'Please upload related files',
        imgTips:'Please upload related images',
    },
    //Components-engineer-Sidebar 完成(Common+ProList)
    engineerSidebar:{
        delFile:'Delete Folder',
        delFileTag1:'This folder is empty, are you sure to continue?',
        delFileTag2:'This folder is not empty, deleting the folder will not delete the products in this folder, only the folder will be cleared, are you sure to continue?',
        delFileTag3:'This folder is not empty, deleting the folder will delete all folders under this folder, but not delete the products in this folder, are you sure to continue?',
    },
    //* Components-engineer-SubMenu 完成(Common)
    SubMenu:{
        Member:'Member Management',
        Company:'Company Info',
        certified:'Certified',
        notCertified:'Not Certified',
        person:'Person Account',
        businessMaster:'Business Master',
        businessSub:'Business Sub',
        business:'Business Account',
        expired:'Expired',
        myCalcTime:'Core Hours',
        myPackage:'Package',
        noPackage:'No Available Package ',
        toBuy:'To Buy',
        toUpdate:'To Upgrade',
        totalCalcTime:'Total',
        usedCalcTime:'Used',
        avaliableCalcTime:'Available',
    },
        //? Components-design 完成
    //Components-design-AnalyseSidebar 完成(importData+Common)
    //* Components-design-ApplyConverse 完成(ApplyCurve)
    ApplyConverse:{
        applyAutoDesign:'Design',
    },
    //Components-design-DesignNav 完成(Common+EngineerNav+ProList)
    DesignNav:{
        system:'System',
        enterprise:'Enterprise',
        untitled:'Untitled',
        help:'Help documents',
        helpText:'Help',
    },
    //Components-design-DesignSidebar 完成(Common+DesignIndex+importData+EngineerIndex)
    DesignSidebar:{
        moreOptions:'More',
        syncSave:'Synchronize and save data to R&D library and overwrite the original lamination in R&D library.',
        syncSave2:'The lamination will be saved to the R&D library and overwrite the original lamination in the R&D library.',
        coverLib:'Overwrite R&D Library Lamination',
        coverDone:'Overwritten',
        lamLib:'Library',
        lamCopy:'New Copy',
        lamCreate:'New Design',
        collapse:'Fold',
        expand:'Open',
        cost:'Cost',
        performance:'Performance',
        volume:'Volume',
        weight:'Weight',
        puchingNumError:'The number of lamination poles does not meet the conditions',
        negativeError:'Cannot be negative',
        cannotEmpty:'Cannot be empty',
        mustNumber:' should be number',
        mustNumber2:'Should be number',
        notRepeat:' cannot be repeated',
        copy:'Duplicate',
        none:'Null',
        collapseCondition:'Fold',
        expandCondition:'Expand',
        customConditionName:'Solution Name',
        addCustomTitle:'Add custom solution',
        customConditionError:'Enter the Solution name',
        delCustomTitle:'Delete custom solution',
        delCustomTips:'Are you sure to delete the custom solution?',
    },
    //Components-design-LaminationEdit 无
    //Components-design-LaminationList 完成(Common+importData+OrderIndex+DesignIndex)
    LaminationList:{
        chooseLam:'Select Lamination',
        noLamination:'No Lamination',
        PLATFORM:"System Library",
        ENTERPRISE:"Enterprise Library",
        PERSON:"Person Library",
        TEMPORARY:"R&D Library",
    },
    //Components-design-MaterialDrawer 完成(Common)
    MaterialDrawer:{
        silicon:'Silicon Steel',
        magnetic:'Magnet Steel',
        other:'Other',
        siliconSelect:'More Core Materials',
        magneticSelect:'More Magnet Materials',
        selectMaterial:'Select Materials',
        selected:'Selected Materials',
        noMaterial:'No Material',
        searchByName:"Search By Name",
        category:"Type",
        grade:"Brands",
        heatLevel:"Heat Resistance Class",
        name:"Name",
        sendBenefitMail:'Upgrade',
        STANDARD:'Standard',
        USER:'User',
    },
    //Components-design-OptionSelect 完成(Common)
    OptionSelect:{
        inputNum:'Enter Number',
        inputText:'Enter Text',
    },
    //Components-design-RSDrawer 完成(Common+importData)

        //材料库Components-lib
        Lib:{
            navLib0:'Material Library',
            navLib1:'Magnet Material',
            navLib2:'Silicon Steel Material',
            navLib3:'Other Material',
            selectLib1:'Magnet Material Library',
            selectLib2:'Silicon Steel Material Library',
            selectLib3:'Other Material Library',
            otherMaterial1:'Conductor',
            otherMaterial2:'Shaft Material',
            otherMaterial3:'Base Material',
            otherMaterial4:'Insulation Material',
            submitSelect:'Confirm',
            housingSelected:'Selected Housing',
    
            name:'Name',
            materialName:'Material Name',
            libraryId:'Library Name',
            category:'Category',
            categoryId:'Material Category',
            manufactureId:'Manufacturer',
            supplierName:'Supplier',
            initPrice:'Unit Price(yuan/kg)',
            initPriceUnit:'RMB',
            refTemprature:'Reference Temperature',
            conductivity:'Conductivity',
            relativePermittivity:'Relative Dielectric Constant',
            resTempCoeff:'Resistance Temperature Coefficient',
            relativePermeabilityGroup:'Relative Permeability',
            linear:'Linear',
            notLinear:'Nonlinear',
            relativePermeCurve:'B-H Curve',
            relativePermeCurve2:'H-B Curve',
            magOrPer:'Residual Mag Strength(Br)/Intrinsic Coercivity(Mp)',
            magRetentivity:'Residual Mag Strength',
            permMagnetizatn:'Intrinsic Coercivity',
            magCoercivity:'MagCoercivity',
            magTempCoeff:'Magnet Temperature Coefficient',
            massDensity:'Mass Density',
            specificHeat:'Specific Heat',
            thermalConducty:'Thermal Conductivity',
            youngsModule:'Young Modulus',
            poissonsRatio:'Poissons Ratio',
            magneticComputedDialog:'Magnet Properties',
            coreLossTypeUnit:'Loss Factor of Silicon Steel Sheet',
            coreThickness:'Magnet Thickness',
            coreLossTypeCurve:'Core Loss Curve',
            coreLossTypeCurve2:'B-P Curve',
            bhcF:'Magnetic Flux Density[T]',
            bhcFUnit:'B Unit(B)',
            tesla:'T',
            gauss:'G',
            bhcM:'Magnetic Field Strength[A/m]',
            bhcMUnit:'H Unit(H)',
            bhcMUnit1:'A/m',
            bhcMUnit2:'kA/m',
            bhcMUnit3:'Oe',
            bhcMUnit4:'kOe',
            bpcP:'Power Loss per Unit[W/kg]',
            bpcPUnit:'P Unit(P)',
    
            bpcPUnit1:'W/m^3',
            bpcPUnit2:'kW/m^3',
            bpcPUnit3:'W/lb',
            bpcPUnit4:'W/kg',
            bhcFreq:'Frequency(Hz)',
            bhcThick:'Thickness(mm)',
    
    
            unit:'Unit',
            editUnit:'Edit Unit',
    
            select:'Select ',
            selected:'Selected Material',
            submitSelect:'Select Done',
            create:'New',
            create2:'Create ',
            copy:'Copy',
            delete:'Delete',
            material:'Material',
            deleteMaterial:'Delete Material',
            cannotEmpty:' cannot be empty',
            copyDone:'Material has copied to User library',
    
            editor:' Editor',
            edit:'Edit ',
            show:'Show ',
            materialRepeat:'There is the same name in the library.',
            setCorrect:'Please set the correct ',
    
            addPoint:'Add Point',
            delPoint:'Remove Point',
            insertPoint:'Insert Point',
            sortPoints:'Sort Point',
            AddCurve:'Add',
            delCurve:'Remove',
            importCurve:'Import',
            exportCurve:'Export',
            curveList:'Curve List',
            temprature:'Temperature',
            frequency:'Frequency',
            temp:'Temp',
            freq:'Freq',
            centigrade:'Celsius degree',
            importTips:'This operation will delete all points that already defined.Are you sure to continue?',
            curveTips:'Curve with the same temperature and frequency already exists.',
            csvFile:'Only supports uploading files in csv format',
            BHcsv:'BH Curve.csv',
            BPcsv:'BP Curve.csv'
        },
}
export default English